import { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Invested_Users_COLUMNS } from '../../../Constant/Column/column';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const StrategyDetailsTable = ({ investedUserLists, pageNumber, usersPerPage }: any) => {
  // Custom sorting function for case insensitive sorting
  const caseInsensitiveSort = (rowA: any, rowB: any, columnId: string) => {
    const valueA = rowA.values[columnId];
    const valueB = rowB.values[columnId];

    // Handle undefined or null values
    if (valueA == null) return 1;
    if (valueB == null) return -1;

    // If both values are numbers, use a numeric comparison
    if (!isNaN(valueA) && !isNaN(valueB)) {
      return Number(valueA) - Number(valueB);
    }

    // Otherwise, perform case-insensitive string comparison
    return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
  };

  const columns = useMemo<any>(() => {
    return Invested_Users_COLUMNS.map(column => {
      return {
        ...column,
        // Use the custom sorting function for columns that allow sorting
        sortType: caseInsensitiveSort,
      };
    });
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: investedUserLists,
    },
    useSortBy,
  );

  // Slice sorted rows based on pagination values
  const pagesVisited = pageNumber * usersPerPage;
  const sortedRows = [...rows]; // Make a copy to preserve original row order
  const paginatedRows = sortedRows.slice(pagesVisited, pagesVisited + usersPerPage);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, colKey) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={colKey}>
                  <div className='flex items-center justify-center mx-auto'>
                    <span>{column.render('Header')}</span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown className='ml-1' />
                      ) : (
                        <FaSortUp className='ml-1' />
                      )
                    ) : (
                      <FaSort className='ml-1' />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {investedUserLists?.length ? (
          <tbody {...getTableBodyProps()}>
            {paginatedRows.map((row, index) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell: any, colKey) => {
                      return (
                        <td {...cell.getCellProps()} key={colKey}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <th className='relative left-1/3 '>No matching records found</th>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
};

export default StrategyDetailsTable;
