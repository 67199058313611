import Button from '../../../components/Buttons/button';
import EditableInput from '../../../components/InputFields/EditableInput';
import { Investor_Type, isIRA } from '../../../Constant/IRAPanelData';
import { EditUserDetailsProps } from '../../../Constant/Types/types';
import { useEffect, useState } from 'react';

interface EditAddressInfoProps {
  userApplication: EditUserDetailsProps;
  handleChange: {
    (e: React.ChangeEvent<HTMLInputElement>): void;
    <T = string | React.ChangeEvent<HTMLInputElement>>(field: T): T extends React.ChangeEvent<HTMLInputElement>
      ? void
      : (e: string | React.ChangeEvent<HTMLInputElement>) => void;
  };
  handleSubmit: () => void;
  loading: boolean;
  errors: any;
  investor_type: string;
  ssn: string;
  setLoading: (loading: boolean) => void;
}

const EditAddressInfo = ({
  userApplication,
  handleChange,
  errors,
  handleSubmit,
  loading,
  investor_type,
  ssn,
  setLoading,
}: EditAddressInfoProps) => {
  const [zipError, setZipError] = useState<string>('');

  useEffect(() => {
    // Convert the zipCode to a string for length validation
    const zipCodeStr = userApplication.zipCode ? userApplication.zipCode.toString() : '';

    // Reset the zip error whenever ssn or zipCode changes
    if (zipCodeStr.length === 0) {
      setZipError('ZipCode is required*');
    } else if (ssn !== '' && zipCodeStr.length !== 5) {
      setZipError('Must be 5 digits*');
    } else if (ssn === '' && zipCodeStr.length > 10) {
      setZipError('Must be 10 digits or less*');
    } else {
      setZipError('');
    }
  }, [ssn, userApplication.zipCode]);

  // Modified handleChange to set loading to true on input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true); // Set loading to true on each change
    handleChange(e);
  };
  // Check if any errors exist excluding 'dob'
  const hasErrorsExcludingDOB = Object.keys(errors).some(key => key !== 'dob' && errors[key] !== '');
  return (
    <div>
      <div className='mx-4 pb-4 px-3'>
        <h2 className='text-lg font-medium mt-8 heading'>ADDRESS INFO</h2>
      </div>
      <hr className='hrLine' />
      <div className='mt-6 px-6'>
        <div className='grid mx-6 grid-cols-3'>
          <EditableInput
            heading='Address 1'
            type='text'
            value={userApplication.address1}
            onChange={handleInputChange}
            className='inputStyle'
            name='address1'
            error={errors.address1}
          />
          <EditableInput
            heading='Address 2'
            type='text'
            value={userApplication.address2 || ''}
            onChange={handleInputChange}
            className='inputStyle'
            name='address2'
          />
          <EditableInput
            heading='City'
            type='text'
            value={userApplication.city}
            onChange={handleInputChange}
            className='inputStyle'
            name='city'
            error={errors.city}
          />
          {investor_type === Investor_Type.NON_US_ENTITY || investor_type === Investor_Type.NON_US_USER ? (
            <EditableInput
              heading='Country'
              type='text'
              value={userApplication.country}
              onChange={handleInputChange}
              className='inputStyle'
              name='country'
              error={errors.country}
            />
          ) : null}
          {investor_type === Investor_Type.US_ENTITY || investor_type === Investor_Type.US_USER || isIRA(investor_type) ? (
            <EditableInput
              heading='State'
              type='text'
              value={userApplication.state}
              onChange={handleInputChange}
              className='inputStyle'
              name='state'
              error={errors.state}
            />
          ) : null}
          <EditableInput
            heading='Zip Code'
            type='number'
            value={userApplication.zipCode || ''}
            onChange={handleInputChange}
            className='inputStyle'
            name='zipCode'
            error={zipError}
          />
        </div>
      </div>
      <div className='alignment mt-6'>
        <Button
          text='Save User'
          style='m-1 w-32 text-center bg-darkblue'
          disabled={loading ? !!hasErrorsExcludingDOB || !!zipError : true}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default EditAddressInfo;
