import { useEffect, useState } from 'react';
import Button from '../../../components/Buttons/button';
import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import Details from '../../../components/Details/details';
import { UserDetailsProps } from '../../../Constant/Types/types';
import { IRA_ADMIN } from '../../../Api/panelApis';
import { toastError, toastSuccess } from '../../../Utils/helper';
import Modal from '../../../components/Modal/modal';

interface AddressInfoProps {
  userDetails: UserDetailsProps;
  userId: string;
}

const AddressInfo = ({ userDetails, userId }: AddressInfoProps) => {
  const [investorStatusButton, setInvestorStatusButton] = useState('release');
  const [isinvestorStatusButtonDisabled, setisinvestorStatusButtonDisabled] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState('');
  const [isModalOpen, setisModalOpen] = useState(false);
  const handleReleaseAccreditedInvestorLetter = async () => {
    try {
      const data = {
        release_type: 'payment_link',
        user_id: userId,
      };
      const response = await IRA_ADMIN.updateCertificateReleaseStatus(data);
      if (response.success) {
        toastSuccess('Certificate released successfully.');
        setCertificateUrl(response.certificate_url);
      }
    } catch (error: any) {
      toastError(error.response.data.error);
    }
  };

  const handleViewAccreditedInvestorLetter = () => {
    console.log('certificateUrl ?', certificateUrl);
    setisModalOpen(true);
  };

  useEffect(() => {
    const getCertificate = async (userId: string) => {
      try {
        const response: any = await IRA_ADMIN.getCertificateUrl(parseInt(userId));

        if (response.success) {
          const { accredited_investor_letter_status, expiration_date } = userDetails?.investor_status || {};

          const isExpired = new Date(expiration_date) < new Date();
          if (accredited_investor_letter_status === 'ISSUED') {
            if (isExpired) {
              toastError('Certificate is expired.');
              setInvestorStatusButton('release');
              setisinvestorStatusButtonDisabled(false);
            } else {
              setCertificateUrl(response.certificate_url);
              setInvestorStatusButton('view');
            }
          } else {
            setInvestorStatusButton('release');
            setisinvestorStatusButtonDisabled(false);
          }
        }
      } catch (error: any) {
        setInvestorStatusButton('release');
        setisinvestorStatusButtonDisabled(false);
      }
    };

    getCertificate(userId);
  }, []);

  return (
    <CollapsibleContainer title='INVESTOR STATUS'>
      <div className='mt-6 px-6'>
        <div className='grid grid-cols-3'>
          <Details heading='Accreditation Status' detail={userDetails?.investor_status?.accredited_status} />
          <Details
            heading='Accredited Investor Letter Status'
            detail={userDetails?.investor_status.accredited_investor_letter_status || 'none'}
          />
          <Details
            heading='Expiration Date'
            detail={
              userDetails?.investor_status.expiration_date
                ? new Date(userDetails.investor_status.expiration_date).toLocaleDateString('en-GB')
                : ''
            }
          />
          <Details heading='Requested by' detail={userDetails?.investor_status.requested_by || ''} />
        </div>
      </div>
      <Modal viewCertificate={true} setisModalOpen={setisModalOpen} isModalOpen={isModalOpen} certificateUrl={certificateUrl} />
      <div className='mt-6 px-6'>
        <div className='alignmentBetween mx-6  flex-wrap'></div>
        <div className='alignmentBetween mx-6  flex-wrap'></div>
      </div>
      <div className='alignment'>
        {investorStatusButton === 'release' ? (
          <Button
            text='Release Accredited Investor Letter'
            style='bg-darkblue text-white border-2 border-transparent rounded-md hover:bg-darkblue m-4 hover:opacity-70 focus:ring-red w-full'
            onClick={handleReleaseAccreditedInvestorLetter}
            disabled={isinvestorStatusButtonDisabled}
          />
        ) : (
          <Button
            text='View'
            style='bg-darkblue text-white border-2 border-transparent rounded-md hover:bg-darkblue m-4 hover:opacity-70 focus:ring-red w-full'
            onClick={handleViewAccreditedInvestorLetter}
          />
        )}
      </div>
    </CollapsibleContainer>
  );
};

export default AddressInfo;
