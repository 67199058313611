import dayjs from 'dayjs';
import EditableInput from '../../../components/InputFields/EditableInput';
import EditableSelectInput from '../../../components/InputFields/EditableSelectInput';
import { ACCREDITATION_STATUS, Investor_Type, isIRA, MARRITAL_STATUS } from '../../../Constant/IRAPanelData';
import { EditUserDetailsProps } from '../../../Constant/Types/types';
import { useEffect, useState } from 'react';

interface EditPersonalInfoProps {
  userApplication: EditUserDetailsProps;
  handleChange: {
    (e: React.ChangeEvent<HTMLInputElement>): void;
    <T = string | React.ChangeEvent<HTMLInputElement>>(field: T): T extends React.ChangeEvent<HTMLInputElement>
      ? void
      : (e: string | React.ChangeEvent<HTMLInputElement>) => void;
  };
  investor_type: string;
  errors: any;
  setLoading: (loading: boolean) => void;
}

const EditPersonalInfo = ({ userApplication, handleChange, errors, investor_type, setLoading }: EditPersonalInfoProps) => {
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [dobError, setDobError] = useState<string>('');

  // Utility function to calculate age
  const calculateAge = (dob: string) => {
    const birthDate = dayjs(dob);
    const today = dayjs();
    return today.diff(birthDate, 'year');
  };

  useEffect(() => {
    const firstName = userApplication.first_name || '';

    if (firstName.length > 30) {
      setFirstNameError('Must be 30 characters or less*');
    } else if (firstName.length === 0) {
      setFirstNameError('First name is required*');
    } else {
      setFirstNameError('');
    }
  }, [userApplication.first_name]);
  useEffect(() => {
    const lastName = userApplication.last_name || '';

    if (lastName.length > 30) {
      setLastNameError('Must be 30 characters or less*');
    } else if (lastName.length === 0) {
      setLastNameError('Last name is required*');
    } else {
      setLastNameError('');
    }
  }, [userApplication.last_name]);

  useEffect(() => {
    const dob = userApplication.dob || '';
    if (dob === '') {
      setDobError('Date of birth is required*');
    } else if (dob) {
      const age = calculateAge(dob);
      if (age < 13) {
        setDobError('You must be at least 13 years old*');
      } else {
        setDobError('');
      }
    }
  }, [userApplication.dob]);

  // Modified handleChange to set loading to true on input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true); // Set loading to true on each change
    handleChange(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className='alignmentBetween mx-4 py-4 px-2'>
        <h2 className='text-lg font-medium heading'>PERSONAL INFO</h2>
      </div>
      <hr className='hrLine' />
      <div className='mt-6 px-6'>
        <div className='grid  mx-6 grid-cols-3'>
          <EditableInput
            heading='First name'
            type='text'
            value={userApplication.first_name}
            onChange={handleInputChange}
            className='inputStyle'
            name='first_name'
            error={firstNameError}
          />
          <EditableInput
            heading='Last name'
            type='text'
            value={userApplication.last_name}
            onChange={handleInputChange}
            className='inputStyle'
            name='last_name'
            error={lastNameError}
          />
          {isIRA(investor_type) || investor_type === Investor_Type.US_USER ? (
            <EditableInput
              heading='Date Of Birth'
              type='date'
              value={dayjs(userApplication.dob, 'MM-DD-YYYY').format('YYYY-MM-DD')}
              onChange={handleInputChange}
              className='inputStyle'
              name='dob'
              error={dobError}
            />
          ) : null}
          {investor_type === Investor_Type.US_ENTITY ? (
            <EditableInput
              heading='EIN'
              type='number'
              value={userApplication.ein}
              onChange={handleInputChange}
              className='inputStyle'
              error={errors.ein}
              name='ein'
              onKeyDown={handleKeyDown}
            />
          ) : null}
          {isIRA(investor_type) || investor_type === Investor_Type.US_USER || investor_type === Investor_Type.US_ENTITY ? (
            <EditableInput
              heading='SSN'
              type='number'
              value={userApplication.ssn}
              onChange={handleInputChange}
              className='inputStyle'
              error={errors.ssn}
              name='ssn'
              onKeyDown={handleKeyDown}
            />
          ) : null}
          {investor_type === Investor_Type.US_ENTITY || investor_type === Investor_Type.NON_US_ENTITY ? (
            <EditableInput
              heading='Company Name'
              type='text'
              value={userApplication.company_name}
              onChange={handleInputChange}
              className='inputStyle'
              error={errors.company_name}
              name='company_name'
            />
          ) : null}
          {isIRA(investor_type) ? (
            <>
              <EditableSelectInput
                captionKey='id'
                handler={handleChange}
                className='inputStyle'
                items={MARRITAL_STATUS}
                selected={userApplication.marrital_status}
                heading={'Marital Status'}
                styles={''}
                name='marrital_status'
              />
              <EditableSelectInput
                captionKey='id'
                handler={handleChange}
                className='inputStyle'
                items={ACCREDITATION_STATUS}
                selected={userApplication.accreditation_type}
                heading={'accreditation status'}
                styles={''}
                name='accreditation_type'
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EditPersonalInfo;
