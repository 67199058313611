import carlLogo from '../../assets/carl-logo.png';
import Button from '../../components/Buttons/button';
import FormInput from '../../components/InputFields/FormInput';
import { useState } from 'react';
import { IRA_ADMIN } from '../../Api/panelApis';
import { toastError, toastSuccess } from '../../Utils/helper';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isResend, setIsResend] = useState(true);

  const handleSubmit = async () => {
    try {
      const response = await IRA_ADMIN.resetByEmail(email);
      toastSuccess(response.message);
      setIsResend(!isResend);
    } catch (error: any) {
      toastError(error.response.data.message);
    }
  };

  return (
    <div className='alignment min-h-full px-4 py-12 sm:px-6 lg:px-8 mt-10'>
      <div className='w-full max-w-md space-y-8 p-4 rounded-lg shadow-2xl'>
        <div>
          <img className='w-auto h-24 mx-auto' src={carlLogo} alt='logo' />
          <h2 className='mt-6 text-2xl font-bold text-center  text-darkblue'>Reset Password</h2>
        </div>
        <FormInput
          type={'email'}
          style='w-full'
          placeholder='Enter Your Email'
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />
        <div className='mb-5 active:border-none'>
          <Button
            text={'Send Password Reset Link'}
            style=' w-full px-4 py-2 text-md font-medium text-white mb-2 bg-darkblue border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={handleSubmit}
            disabled={email === '' || !isResend}
          />
          <Button
            text={'Resend Link'}
            style=' w-32 bg-transparent text-darkblue hover:bg-transparent m-auto font-extrabold text-md active:border-none active:bg-transparent disabled:opacity-80 disabled:cursor-not-allowed  focus:outline-none focus:ring focus:ring-transparent '
            onClick={handleSubmit}
            disabled={email === '' || isResend}
          />
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
