import { Listbox } from '@headlessui/react';
import { useMemo, useState } from 'react';
import { MdRemoveRedEye } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import { Strategies_COLUMNS } from '../../../Constant/Column/column';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const InvestedStrategiesTable = ({ strategies }: any) => {
  const [checkButton, setcheckButton] = useState('');
  const navigate = useNavigate();

  const handleCick = (data: any) => {
    const notOrifignaldata = data.map((item: any) => ({
      row: { cells: item.row.cells.map((cell: any) => ({ key: cell?.column?.Header || 'KEY', value: cell.value })) },
    }));
    localStorage.setItem('strategy_details', JSON.stringify(notOrifignaldata));
    navigate('/strategy_details', { state: { data: notOrifignaldata } });
  };
  // Custom sorting function for case insensitive sorting
  const caseInsensitiveSort = (rowA: any, rowB: any, columnId: string) => {
    const valueA = rowA.values[columnId];
    const valueB = rowB.values[columnId];

    // Handle undefined or null values
    if (valueA == null) return 1;
    if (valueB == null) return -1;

    // If both values are numbers, use a numeric comparison
    if (!isNaN(valueA) && !isNaN(valueB)) {
      return Number(valueA) - Number(valueB);
    }

    // Otherwise, perform case-insensitive string comparison
    return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
  };
  const columns = useMemo<any>(() => {
    return Strategies_COLUMNS.map(column => {
      return {
        ...column,
        // Use the custom sorting function for columns that allow sorting
        sortType: caseInsensitiveSort,
      };
    });
  }, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: strategies,
    },
    useSortBy,
  );
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, colKey) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} key={colKey}>
                <div className='flex items-center justify-center mx-auto'>
                  <span>{column.render('Header')}</span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FaSortDown className='ml-1' />
                    ) : (
                      <FaSortUp className='ml-1' />
                    )
                  ) : (
                    <FaSort className='ml-1' />
                  )}
                </div>
              </th>
            ))}
            <th>View</th>
          </tr>
        ))}
      </thead>
      {strategies.length ? (
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} key={index}>
                  {row.cells
                    .filter(item => item.value)
                    .map((cell: any, colKey) => {
                      return (
                        <td {...cell.getCellProps()} key={colKey}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  <td className='text-center'>
                    <Listbox value={checkButton} onChange={setcheckButton}>
                      <Listbox.Button className='text-center mx-auto'>
                        <div
                          onClick={() => {
                            handleCick(row.cells);
                          }}
                        >
                          <MdRemoveRedEye />
                        </div>
                      </Listbox.Button>
                    </Listbox>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <th className='relative left-1/3 '>No matching records found</th>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default InvestedStrategiesTable;
