import React, { useCallback, useState, useRef } from 'react';
import Table from '../../Table/table';
import './iraPanel.css';
import { APPLICATION_DURATION, APPLICATION_STATUS, exceptThisSymbols, SEARCH_TYPES } from '../../../../Constant/IRAPanelData';
import Dropdown from '../../../../components/dropdown/dropdown';
import HeaderBar from '../../../../components/headerBar/headerBar';
import HeaderCard from '../../../../components/Sections/HomeScreen/headerCard';
import { MdSearch } from 'react-icons/md';
import { IRA_ADMIN } from '../../../../Api/panelApis';
import { ThreeDots } from 'react-loader-spinner';
import { SearchText } from '../../../../Utils/helper';
import ReactPaginate from 'react-paginate';

interface UsersProps {
  application_status: string;
  country: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  phone_number: string;
  started_at: string;
}

const Users = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const route = window?.location?.href?.split('/').at(-1);
  // const [applicationStatus, setApplicationStatus] = React.useState('ALL');
  // Initialize state from sessionStorage or default values
  const [applicationStatus, setApplicationStatus] = useState(sessionStorage.getItem('applicationStatus') || 'ALL');
  const [duration, setDuration] = useState(sessionStorage.getItem('duration') || 'ALL TIME');
  const [users, setUsers] = React.useState([]);
  const [searchData, setSearchData] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [visited, setVisited] = useState<number[]>([]);
  const [searchType, setSearchType] = useState('first_name');
  const [isSearch, setIsSearch] = useState(false);
  // const [duration, setDuration] = useState('ALL TIME');
  const [isFilter, setIsFilter] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [appStatus, setAppStatus] = useState({
    incompleteUsers: '',
    inProcess: '',
    approved: '',
    resubmit: '',
    notStarted: '',
    decline: '',
    total: 0,
  });
  const [filteredAppStatus, setfilteredStatus] = useState({
    incompleteUsers: '',
    inProcess: '',
    approved: '',
    resubmit: '',
    notStarted: '',
    decline: '',
    total: 0,
  });

  // Use the filteredUsers if duration filter is active, otherwise use users
  const applications = (isFilter ? filteredUsers : users).filter((user: UsersProps) => {
    if (applicationStatus === 'ALL') return true;
    return (
      user.application_status === applicationStatus || (applicationStatus === 'IN PROCESS' && user.application_status === 'BANK APPROVED')
    );
  });

  // Display users based on search or filter criteria
  const displayUsers = isSearch ? searchResult : applications;
  const usersPerPage = 50;
  const pageCount = Math.ceil(displayUsers?.length / usersPerPage);

  const changePage = ({ selected }: any) => {
    setPageNumber(selected);
  };

  const getAllUser = useCallback(
    async signal => {
      if (duration === 'user_application_ninety_day' || duration === 'user_application_thirty_day') {
        setIsFilter(true);
        setIsLoading(true);
        const response = await IRA_ADMIN.getFilteredUsers(signal, duration);
        const {
          data,
          count: { approved, declined, in_process, onboarding_pending, bank_approved, bank_pending, not_started, resubmit, total_users },
        }: any = response;
        setIsLoading(false);
        setFilteredUsers(data);
        setfilteredStatus({
          ...filteredAppStatus,
          approved,
          decline: declined,
          inProcess: in_process + bank_approved + bank_pending,
          incompleteUsers: onboarding_pending + not_started,
          resubmit: resubmit,
          total: total_users,
        });
      } else {
        setIsFilter(false);
        setIsLoading(true);
        const response = await IRA_ADMIN.getAllUsers(signal);
        const {
          data,
          count: { approved, declined, in_process, onboarding_pending, bank_approved, bank_pending, not_started, resubmit, total_users },
        }: any = response;
        setIsLoading(false);
        setUsers(data);
        setVisited([...visited, data?.length]);
        setAppStatus({
          ...appStatus,
          approved,
          decline: declined,
          inProcess: in_process + bank_approved + bank_pending,
          incompleteUsers: onboarding_pending,
          notStarted: not_started,
          resubmit: resubmit,
          total: total_users,
        });
      }
    },
    [duration],
  );

  // const getRoute = window?.location?.href;
  // const directory = getRoute?.split('/').at(-1);

  const searchUser = async (searchValue: any) => {
    // Update searchType to 'name' if it's 'first_name'
    const updatedSearchType = searchType === 'first_name' ? 'name' : searchType;

    const search = {
      [updatedSearchType]: updatedSearchType === 'phone_number' ? '+' + searchValue : searchValue,
      applications: true,
    };

    try {
      setIsLoading(true);
      const res = await IRA_ADMIN.searchUser(search);
      setIsLoading(false);
      setSearchResult(res);
      setIsSearch(true);
    } catch (err) {
      console.log({ err });
    }
  };

  React.useEffect(() => {
    setVisited([]);
    setUsers([]);
    setPageNumber(0); // Reset page number when the route changes
  }, [route]);

  React.useEffect(() => {
    sessionStorage.setItem('applicationStatus', applicationStatus);
    setPageNumber(0); // Reset page number when application status changes
  }, [applicationStatus]);

  React.useEffect(() => {
    sessionStorage.setItem('duration', duration);
    setPageNumber(0); // Reset page number when duration changes
  }, [duration]);

  const { incompleteUsers, inProcess, approved, resubmit, notStarted, decline } = isFilter ? filteredAppStatus : appStatus;

  const cardsData = {
    incompleteUsers,
    inProcess,
    approved,
    resubmit,
    notStarted,
    decline,
  };

  React.useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
    setIsSearch(false);
    const controller = new AbortController();
    getAllUser(controller.signal);
    window.scrollTo({ top: 0, left: 0 });
    return () => {
      controller.abort();
    };
  }, [getAllUser, route]);

  const submit = (e: any) => {
    e.preventDefault();
    searchUser(searchData);
  };

  const onChange = (e: any) => {
    setSearchData(e.target.value);
    if (!e.target.value.length) {
      setIsSearch(false);
    }
  };

  return (
    <div className='panelTableContainer'>
      <HeaderBar title='Onboarding Application' totalApplication={isFilter ? filteredAppStatus.total : appStatus?.total} />
      <HeaderCard isLoading={isLoading} setApplicationStatus={setApplicationStatus} cardsData={cardsData} />
      <div className='panelTable'>
        <div className='dropDownSection'>
          <div className='panelHeadingContainer'>
            <div className='flex '>
              <form onSubmit={submit} className='searchInputContainer'>
                <input
                  ref={inputRef}
                  type={searchType === 'phone_number' || searchType === 'user_id' ? 'number' : 'text'}
                  placeholder={`Search by ${SearchText(searchType)}`}
                  onChange={onChange}
                  onKeyDown={e =>
                    (searchType === 'phone_number' || searchType === 'user_id') && exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                  className='searchInput'
                  required
                />
                <button type='submit' onSubmit={submit}>
                  <MdSearch size={24} color='lightgrey' className='cursor-pointer mx-2' />
                </button>
              </form>
              <div>
                <Dropdown items={SEARCH_TYPES} selected={searchType} captionKey='value' className='dropdownInput' handler={setSearchType} />
              </div>
            </div>
            <div>
              <Dropdown
                items={APPLICATION_DURATION}
                selected={duration}
                captionKey='value'
                className='dropdownInput'
                handler={setDuration}
              />
              <Dropdown
                items={APPLICATION_STATUS}
                selected={applicationStatus}
                captionKey='value'
                className='dropdownInput'
                handler={setApplicationStatus}
              />
            </div>
          </div>
          {isLoading ? (
            <div className='alignment h-full' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <div className='tablescroll'>
              <Table userData={displayUsers} pageNumber={pageNumber} usersPerPage={usersPerPage} />
            </div>
          )}
          {!isLoading && displayUsers.length && (
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
              forcePage={pageNumber}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Users;
