import React, { useState } from 'react';
import carlLogo from '../../assets/carl-logo.png';
import Button from '../../components/Buttons/button';
import { useNavigate } from 'react-router-dom';
import FormInput from '../../components/InputFields/FormInput';
import { IRA_ADMIN } from '../../Api/panelApis';
import { AppContext } from '../../Context/Context';
import { toastError, toastSuccess } from '../../Utils/helper';

const EnterOTP = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [OTP, setOTP] = useState('');
  const { setToken } = React.useContext(AppContext);

  const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow only numeric input and restrict to 4 digits
    if (/^\d{0,4}$/.test(value)) {
      setOTP(value);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const user_id = localStorage.getItem('user_id');
    try {
      const response = await IRA_ADMIN.verifyOTP(Number(OTP), Number(user_id));
      if (response.Success) {
        toastSuccess('Login succesfully.');
        sessionStorage.setItem('token', response.token);
        sessionStorage.setItem('adminName', response.first_name);
        setToken(response.token);
        navigate('/dashboard', { replace: true });
      }
    } catch (error: any) {
      setLoading(false);
      toastError('OTP is invalid.');
    }
  };

  return (
    <div className='alignment min-h-full py-12 sm:px-6 lg:px-8 mt-10'>
      <div className='w-full max-w-md space-y-8 p-4 rounded-lg shadow-2xl'>
        <div>
          <img className='w-auto h-24 mx-auto' src={carlLogo} alt='logo' />
          <h2 className='mt-6 text-2xl font-bold text-center text-darkblue'>Enter OTP</h2>
        </div>
        <div className='alignment mb-3 w-full'>
          <FormInput type={'text'} value={OTP} style='w-96' placeholder='Enter OTP' onChange={handleOTPChange} />
        </div>
        <div className='mb-2'>
          <Button
            text={'Login'}
            icon={loading ? <i className='fas fa-circle-notch fa-spin fa-md ml-2'></i> : null}
            style='w-96 px-4 py-2 text-md font-medium text-white bg-darkblue border border-transparent rounded-md m-auto'
            onClick={handleSubmit}
            disabled={OTP.length !== 4}
          />
        </div>
      </div>
    </div>
  );
};

export default EnterOTP;
