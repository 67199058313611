import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HeaderBar from '../../components/headerBar/headerBar';
import TransactionSection from './Section/transactionSection';

interface CustomizedState {
  id?: string; // Marking it as optional because it may be undefined after a refresh
}

const AddFunds = () => {
  const location = useLocation();
  const state = location.state as CustomizedState;

  // Use state to manage the id
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    // Check if state contains the id, if not fallback to localStorage
    if (state?.id) {
      setUserId(state.id);
    } else {
      const localStorageUserId = localStorage.getItem('transactionUserId');
      if (localStorageUserId) {
        setUserId(localStorageUserId);
      }
    }
  }, [state]);

  return (
    <div className='w-full max-w-8xl'>
      <HeaderBar title={`Funds Transactions`} />
      <div className='inline-block w-full max-w-8xl p-6 overflow-hidden text-left align-middle transition-all transform rounded-2xl'>
        <div className='w-full max-w-8xl px-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2x2'>
          {userId && <TransactionSection userId={userId} />}
        </div>
      </div>
    </div>
  );
};

export default AddFunds;
