import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import UploadOtherDocument from './uploadOtherDocument';

const PersonalInfo = ({ userDetails, userId }: any) => {
  return (
    <CollapsibleContainer title='THIRD PARTY INFORMATION'>
      <UploadOtherDocument userDetails={userDetails} userId={userId} documentType='thirdParty' />
    </CollapsibleContainer>
  );
};

export default PersonalInfo;
