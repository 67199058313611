import Details from '../../../components/Details/details';
import DocumentImage from '../../../components/DocumentImage/documentImage';
import '../userDetails.css';
import PdfPreview from '../../../components/DocumentImage/pdfPreview';
import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import UploadOtherDocument from './uploadOtherDocument';

const allDocument = ({ userDetails, userId }: any) => {
  return (
    <CollapsibleContainer title='DOCUMENTS'>
      <div className='mt-6 px-6'>
        <div className='flex justify-start flex-wrap'>
          {/* KYC Information */}
          {userDetails?.kycinfodata?.backImageUrl ? (
            <div className='w-[350px]'>
              <Details heading='Document Name' detail={userDetails?.kycinfodata && 'Identification Document'} />
              <div className='flex gap-3'>
                {/* Front Image */}
                {userDetails?.kycinfodata?.frontImageUrl && (
                  <div className='mb-3'>
                    {userDetails.kycinfodata.frontImageUrl.split('.').pop() === 'pdf' ? (
                      <PdfPreview url={userDetails.kycinfodata.frontImageUrl} fileName='' />
                    ) : (
                      <DocumentImage src={userDetails.kycinfodata.frontImageUrl} fileName='' />
                    )}
                  </div>
                )}
                {/* Back Image */}
                {userDetails?.kycinfodata?.backImageUrl && (
                  <div className='mb-3'>
                    {userDetails.kycinfodata.backImageUrl.split('.').pop() === 'pdf' ? (
                      <PdfPreview url={userDetails.kycinfodata.backImageUrl} fileName='' />
                    ) : (
                      <DocumentImage src={userDetails.kycinfodata.backImageUrl} fileName='' />
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className='w-[350px]'>
              <Details heading='Document Name' detail={userDetails?.kycinfodata && 'Identification Document'} />
              {userDetails?.kycinfodata?.frontImageUrl && (
                <div className='mb-3'>
                  {userDetails.kycinfodata.frontImageUrl.split('.').pop() === 'pdf' ? (
                    <PdfPreview url={userDetails.kycinfodata.frontImageUrl} fileName='' />
                  ) : (
                    <DocumentImage src={userDetails.kycinfodata.frontImageUrl} fileName='' />
                  )}
                </div>
              )}
            </div>
          )}
          {/* Address Verification Document */}
          <div className='w-[350px]'>
            <Details heading='Document Name' detail={userDetails?.addressinfodata && 'Address Verification Document'} />
            {userDetails?.addressinfodata?.docFile && (
              <div className='mb-3'>
                {userDetails.addressinfodata.docFile.split('.').pop() === 'pdf' ? (
                  <PdfPreview url={userDetails.addressinfodata.docFile} fileName='' />
                ) : (
                  <DocumentImage src={userDetails.addressinfodata.docFile} fileName='' />
                )}
              </div>
            )}
          </div>

          {/* Authorization Document */}
          <div className='w-[350px]'>
            <Details heading='Document Name' detail={userDetails?.businessentitydata && 'Authorization To Act On Behalf Of The Entity'} />
            {userDetails?.businessentitydata?.image_url && (
              <div className='mb-3'>
                {userDetails.businessentitydata.image_url.split('.').pop() === 'pdf' ? (
                  <PdfPreview url={userDetails.businessentitydata.image_url} fileName={userDetails.businessentitydata.image_name} />
                ) : (
                  <DocumentImage src={userDetails.businessentitydata.image_url} fileName={userDetails.businessentitydata.image_name} />
                )}
              </div>
            )}
          </div>
          <div className='min-w-[350px]'>
            <Details heading='Document Name' detail={userDetails?.accreditedinvestordata && 'Accredited Investor'} />
            <div className='flex justify-center pl-3 flex-wrap'>
              {Object.keys(userDetails?.accreditedinvestordata)?.map((document: any, index: number) => {
                const documentUrl = userDetails?.accreditedinvestordata[document].image_url;

                if (typeof documentUrl === 'string') {
                  const fileExtension = documentUrl.split('.').pop();
                  return (
                    <div key={index} className='flex justify-center px-2'>
                      <div className='alignment flex-col'>
                        <div className='mb-3'>
                          {fileExtension === 'pdf' ? (
                            <PdfPreview url={documentUrl} fileName={userDetails?.accreditedinvestordata[document].image_title} />
                          ) : (
                            <DocumentImage src={documentUrl} fileName={userDetails?.accreditedinvestordata[document].image_title} />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                } else if (typeof documentUrl === 'object' && documentUrl !== null) {
                  // Handle object case, assuming the object might have a URL or file path
                  const fileExtension = documentUrl.url?.split('.').pop(); // Adjust this based on actual object structure
                  return (
                    <div key={index} className='flex justify-center px-2'>
                      <div className='alignment flex-col'>
                        <div className='mb-3'>
                          {fileExtension === 'pdf' ? (
                            <PdfPreview url={documentUrl.url} fileName={userDetails?.accreditedinvestordata[document].image_title} /> // Adjust based on the object's structure
                          ) : (
                            <DocumentImage src={documentUrl.url} fileName={userDetails?.accreditedinvestordata[document].image_title} /> // Adjust based on the object's structure
                          )}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  console.error(`Unexpected type for document: ${typeof documentUrl}`);
                  return null; // Return null if the documentUrl is not a string or valid object
                }
              })}
            </div>
          </div>
        </div>
        <UploadOtherDocument userDetails={userDetails} userId={userId} documentType='other' />
      </div>
    </CollapsibleContainer>
  );
};

export default allDocument;
