import axios from 'axios';

export const axiosObj = {
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const instance = axios.create(axiosObj);

instance.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem('token');
    if (config && config.headers) {
      config.headers.Authorization = token ? `JWT ${token}` : '';
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      sessionStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);
