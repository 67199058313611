import { Listbox } from '@headlessui/react';
import { useMemo, useState } from 'react';
import { MdRemoveRedEye } from 'react-icons/md';
import { useTable, useSortBy } from 'react-table';
import Modal from '../../../components/Modal/modal';
import { Support_Request_COLUMN } from '../../../Constant/Column/column';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const SupportRequestsTable = ({ support_request }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkButton, setcheckButton] = useState('');
  const [requestData, setRequestData] = useState([]);

  // Custom sorting function for case insensitive sorting
  const caseInsensitiveSort = (rowA: any, rowB: any, columnId: string) => {
    const valueA = rowA.values[columnId].toString().toLowerCase();
    const valueB = rowB.values[columnId].toString().toLowerCase();
    return valueA.localeCompare(valueB);
  };

  const columns = useMemo<any>(() => {
    return Support_Request_COLUMN.map(column => {
      return {
        ...column,
        // Use the custom sorting function for columns that allow sorting
        sortType: caseInsensitiveSort,
      };
    });
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: support_request,
    },
    useSortBy,
  );

  const handleCick = (data: any) => {
    const notOrifignaldata = data.map((item: any) => ({
      row: { cells: item.row.cells.map((cell: any) => ({ key: cell?.column?.Header || 'KEY', value: cell.value })) },
    }));
    setRequestData(notOrifignaldata);
    setIsModalOpen(true);
  };
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, colKey) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={colKey}>
                  <div className='flex items-center justify-center mx-auto'>
                    <span>{column.render('Header')}</span>
                    {column.disableSortBy ? null : column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown className='ml-1' />
                      ) : (
                        <FaSortUp className='ml-1' />
                      )
                    ) : (
                      <FaSort className='ml-1' />
                    )}
                  </div>
                </th>
              ))}
              <th>View</th>
            </tr>
          ))}
        </thead>
        {support_request?.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell: any, colKey) => {
                      return (
                        <td {...cell.getCellProps()} key={colKey}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                    <td>
                      <Listbox value={checkButton} onChange={setcheckButton}>
                        <Listbox.Button className='text-center'>
                          <div
                            onClick={() => {
                              handleCick(row.cells);
                            }}
                          >
                            <MdRemoveRedEye />
                          </div>
                        </Listbox.Button>
                      </Listbox>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <th className='relative left-1/3 '>No matching records found</th>
            </tr>
          </tbody>
        )}
      </table>
      <Modal
        style='max-w-xl rounded-3xl'
        viewRequest={true}
        setisModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        requestData={requestData}
      />
    </>
  );
};

export default SupportRequestsTable;
