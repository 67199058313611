import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Users from '../screens/IRAPanel/MainSection/Users/Users';
import IRAPanel from '../screens/IRAPanel/MainSection/Users/IRAPanel';
import UserDetails from '../screens/userDetail/userDetails';
import LeadsUsers from '../screens/IRAPanel/MainSection/LeadsUsers/LeadsUsers';
import AddFunds from '../screens/userDetail/AddFunds';
import Transactions from '../screens/IRAPanel/MainSection/FundsTransactions/Transactions';
import '../screens/IRAPanel/MainSection/Users/iraPanel.css';
import SideBar from '../components/SideBar/sideBar';
import TransactionDetail from '../screens/userDetail/TransactionDetail';
import Dashboard from '../screens/IRAPanel/MainSection/Dashboard/Dashboard';
import Investors from '../screens/IRAPanel/MainSection/Investors/Investors';
import SupportRequests from '../screens/IRAPanel/MainSection/SupportRequests/SupportRequests';
import EditDetails from '../screens/EditatableFields/EditDetails';
import EditTransactions from '../screens/EditatableFields/EditTransactions';
import StrategyDetails from '../screens/userDetail/StrategyDetails';

const ProtectedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Session timeout (1 hour in milliseconds)
  const SESSION_TIMEOUT = 60 * 60 * 1000;

  const handleLogout = () => {
    // Clear storage
    localStorage.clear();
    sessionStorage.clear();
    // Redirect to login page
    navigate('/', { replace: true });
  };

  useEffect(() => {
    // Set the current path in localStorage
    localStorage.setItem('currentPath', location.pathname);

    // Set or update the session expiry time
    const expiryTime = new Date().getTime() + SESSION_TIMEOUT;
    localStorage.setItem('sessionExpiry', expiryTime.toString());

    // Check session timeout periodically
    const interval = setInterval(() => {
      const storedExpiry = localStorage.getItem('sessionExpiry');
      if (storedExpiry && new Date().getTime() > parseInt(storedExpiry, 10)) {
        handleLogout();
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [location, navigate]);

  // Retrieve the last path from localStorage
  const savedPath = localStorage.getItem('currentPath') || '/dashboard';

  return (
    <div className='headerContainer'>
      <div className='mainPanelContainer'>
        <SideBar />
        <Routes>
          <Route path='/ira' element={<IRAPanel />} />
          <Route path='/us_user' element={<Users />} />
          <Route path='/non_us_user' element={<Users />} />
          <Route path='/us_entity' element={<Users />} />
          <Route path='/non_us_entity' element={<Users />} />
          <Route path='/UserDetailsInvestors/:id' element={<UserDetails />} />
          <Route path='/UserDetailsApplications/:id' element={<UserDetails />} />
          <Route path='/UserDetails' element={<UserDetails />} />
          <Route path='/editDetails' element={<EditDetails />} />
          <Route path='/leads' element={<LeadsUsers />} />
          <Route path='/support_requests' element={<SupportRequests />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/investors' element={<Investors />} />
          <Route path='/addFunds' element={<AddFunds />} />
          <Route path='/transactions/ira' element={<Transactions />} />
          <Route path='/transactions/us_user' element={<Transactions />} />
          <Route path='/transactions/non_us_entity' element={<Transactions />} />
          <Route path='/transactions/us_entity' element={<Transactions />} />
          <Route path='/transactions/non_us_user' element={<Transactions />} />
          <Route path='/transactionDetail' element={<TransactionDetail />} />
          <Route path='/editTransaction' element={<EditTransactions />} />
          <Route path='/strategy_details' element={<StrategyDetails />} />

          {/* Wildcard route that dynamically navigates to the last visited page */}
          <Route path='*' element={<Navigate to={savedPath} replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default ProtectedRoutes;
