import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import Details from '../../../components/Details/details';
import { Investor_Type } from '../../../Constant/IRAPanelData';
import { UserDetailsProps } from '../../../Constant/Types/types';

interface AddressInfoProps {
  userDetails: UserDetailsProps;
}

const AddressInfo = ({ userDetails }: AddressInfoProps) => {
  return (
    <CollapsibleContainer title='ADDRESS INFO'>
      <div className='mt-6 px-6'>
        <div className='grid grid-cols-3'>
          <Details heading='Address 1' detail={userDetails?.addressinfodata.address1} />
          <Details heading='Address 2' detail={userDetails?.addressinfodata.address2 || 'none'} />
          <Details heading='City' detail={userDetails?.addressinfodata.city} />
          <Details heading='Zip Code' detail={userDetails?.addressinfodata.zipCode} />
          <Details
            heading={
              userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_ENTITY ||
              userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_USER
                ? 'Country'
                : 'State'
            }
            detail={
              userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_ENTITY ||
              userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_USER
                ? userDetails?.addressinfodata.country
                : userDetails?.addressinfodata.state
            }
          />
          {userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_ENTITY ||
          userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_USER ? null : (
            <Details heading='Country' detail={'United State'} />
          )}
          {userDetails?.personalinfodata?.investor_type === Investor_Type.US_ENTITY && (
            <Details heading='Entity Address Information' detail={userDetails?.addressinfodata.address1 || 'none'} />
          )}
        </div>
      </div>
      <div className='mt-6 px-6'>
        <div className='alignmentBetween mx-6  flex-wrap'></div>
        <div className='alignmentBetween mx-6  flex-wrap'></div>
      </div>
    </CollapsibleContainer>
  );
};

export default AddressInfo;
